:root {
  // --ion-background-color: #0c0d0f;
  // --ion-background-color-rgb: 12, 13, 15;
  // --ion-text-color: #ffffff;
  // --ion-text-color-rgb: 255, 255, 255;
  // --ion-color-step-50: #18191b;
  // --ion-color-step-100: #242527;
  // --ion-color-step-150: #303133;
  // --ion-color-step-200: #3d3d3f;
  // --ion-color-step-250: #494a4b;
  // --ion-color-step-300: #555657;
  // --ion-color-step-350: #616263;
  // --ion-color-step-400: #6d6e6f;
  // --ion-color-step-450: #797a7b;
  // --ion-color-step-500: #868687;
  // --ion-color-step-550: #929293;
  // --ion-color-step-600: #9e9e9f;
  // --ion-color-step-650: #aaaaab;
  // --ion-color-step-700: #b6b6b7;
  // --ion-color-step-750: #c2c3c3;
  // --ion-color-step-800: #cecfcf;
  // --ion-color-step-850: #dbdbdb;
  // --ion-color-step-900: #e7e7e7;
  // --ion-color-step-950: #f3f3f3;

  --ion-color-primary: #f8552c;
  --ion-color-primary-rgb: 248, 85, 44;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #da4b27;
  --ion-color-primary-tint: #f96641;
}

ion-split-pane {
  --side-max-width: 20%;
}

.ck-editor__editable {
  min-height: 250px;
  max-height: 650px;
}

.connector-container {
  width: 20%;
}

.connector-search-form {
  width: 100%;
}

.connector-searchbar {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.media-selector--video-preview {
  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}
